<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            style="background: #ed3029; border: none"
            size="md"
            @click="priceDivisionUpdate"
            >Save</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <tabs fill class="flex-column flex-md-row">
        <card shadow>
          <tab-pane icon="ni ni-cloud-upload-96" title="Iron">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataIron"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[0].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-bell-55 mr-2" title="Bronze">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataBronze"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[1].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Silver">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataSilver"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[2].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Gold">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataGold"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[3].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Platinum">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataPlatinum"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[4].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Diamond">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataDiamond"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[5].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="immortal">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataImmortal"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[6].price[row.number].price
                    "
                  />
                </td>
              </template>
            </base-table>
          </tab-pane>
          <!-- <tab-pane icon="ni ni-calendar-grid-58" title="radiant">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataRadiant"
            >
              <template slot="columns">
                <th>From</th>
                <th></th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>
                <td class="budget">
                  <img src="img/rankBadges/arrow.png" style="width: 100px" />
                </td>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="priceDivision[7].price[row.number].price"
                  />
                </td>
              </template>
            </base-table>
          </tab-pane> -->
        </card>
      </tabs>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "projects-table-valo",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      offreDetails: {},

      priceDivision: [
        {
          name: "Iron",
          divisionNumber: 0,
          price: [
            {
              name: "Iron2",
              price: 12,
              nbPrice: 0,
            },
            {
              name: "Iron1",
              price: 13,
              nbPrice: 1,
            },
            {
              name: "Iron",
              price: 133,
              nbPrice: 2,
            },
          ],
        },
        {
          name: "Bronze",
          divisionNumber: 1,
          price: [
            {
              name: "Bronze2",
              price: 20,
              nbPrice: 5,
            },
            {
              name: "Bronze1",
              price: 20,
              nbPrice: 6,
            },
            {
              name: "Bronze",
              price: 20,
              nbPrice: 7,
            },
          ],
        },
        {
          name: "Silver",
          price: [
            {
              name: "Silver2",
              price: 20,
              nbPrice: 9,
            },
            {
              name: "Silver1",
              price: 20,
              nbPrice: 10,
            },
            {
              name: "Silver",
              price: 20,
              nbPrice: 11,
            },
          ],
        },
        {
          name: "Gold",
          price: [
            {
              name: "Gold2",
              price: 20,
              nbPrice: 13,
            },
            {
              name: "Gold1",
              price: 20,
              nbPrice: 14,
            },
            {
              name: "Gold0",
              price: 20,
              nbPrice: 15,
            },
          ],
        },
        {
          name: "Platinum",
          price: [
            {
              name: "Platinum2",
              price: 20,
              nbPrice: 17,
            },
            {
              name: "Platinum1",
              price: 20,
              nbPrice: 18,
            },
            {
              name: "Platinum0",
              price: 20,
              nbPrice: 19,
            },
          ],
        },
        {
          name: "Diamond",
          price: [
            {
              name: "Diamond2",
              price: 20,
              nbPrice: 21,
            },
            {
              name: "Diamond1",
              price: 20,
              nbPrice: 22,
            },
            {
              name: "Diamond0",
              price: 20,
              nbPrice: 23,
            },
          ],
        },
        {
          name: "Immortal",
          price: [
            {
              name: "Immortal1",
              price: 12,
              nbPrice: 24,
            },
            {
              name: "Immortal",
              price: 20,
              nbPrice: 25,
            },
          ],
        },
        {
          name: "Radiant",
          price: [
            {
              name: "Radiant",
              price: 20,
              nbPrice: 27,
            },
          ],
        },
      ],
      tableDataIron: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_3.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_4.png",
          fromTitle: "Iron 3",
          toTitle: "Iron 2",
          number: 0,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_4.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_5.png",
          fromTitle: "Iron 2",
          toTitle: "Iron 1",
          number: 1,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_5.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_6.png",
          fromTitle: "Iron 1",
          toTitle: "Bronze 4",
          number: 2,
        },
      ],
      tableDataBronze: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_6.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_7.png",
          fromTitle: "Bronze 3",
          toTitle: "Bronze 2",
          number: 0,
        },

        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_7.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_8.png",
          fromTitle: "Bronze 2",
          toTitle: "Bronze 1",
          number: 1,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_8.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_9.png",
          fromTitle: "Bronze 1",
          toTitle: "Silver 4",
          number: 2,
        },
      ],
      tableDataSilver: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_9.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_10.png",
          fromTitle: "Silver 3",
          toTitle: "Silver 2",
          number: 0,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_10.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_11.png",
          fromTitle: "Silver 2",
          toTitle: "Silver 1",
          number: 1,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_11.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_12.png",
          fromTitle: "Silver 1",
          toTitle: "Gold 4",
          number: 2,
        },
      ],
      tableDataGold: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_12.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_13.png",
          fromTitle: "Gold 3",
          toTitle: "Gold 2",
          number: 0,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_13.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_14.png",
          fromTitle: "Gold 2",
          toTitle: "Gold 1",
          number: 1,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_14.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_15.png",
          fromTitle: "Gold 1",
          toTitle: "Platinum 4",
          number: 2,
        },
      ],
      tableDataPlatinum: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_15.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_16.png",
          fromTitle: "Platinum 3",
          toTitle: "Platinum 2",
          number: 0,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_16.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_17.png",
          fromTitle: "Platinum 2",
          toTitle: "Platinum 1",
          number: 1,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_17.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_18.png",
          fromTitle: "Platinum 1",
          toTitle: "Diamond 4",
          number: 2,
        },
      ],
      tableDataDiamond: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_18.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_19.png",
          fromTitle: "Diamond 3",
          toTitle: "Diamond 2",
          number: 0,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_19.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_20.png",
          fromTitle: "Diamond 2",
          toTitle: "Diamond 1",
          number: 1,
        },
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_20.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_23.png",
          fromTitle: "Platinum 1",
          toTitle: "Immortal ",
          number: 2,
        },
      ],
      tableDataImmortal: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_23.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_24.png",
          fromTitle: "Immortal",
          toTitle: "Radiant",
          number: 1,
        },
      ],
      tableDataRadiant: [
        {
          from: "img/rankBadges/TX_CompetitiveTier_Large_23.png",
          to: "img/rankBadges/TX_CompetitiveTier_Large_24.png",
          fromTitle: "Immortal",
          toTitle: "Radiant",
          number: 1,
        },
      ],
    };
  },
  created() {
    this.getOffre();
  },
  methods: {
    getOffre() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/offreDetail/")
        .then((response) => {
          this.offreDetails = response.data.offreDetails[0];
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    priceDivisionUpdate() {
      axios
        .put(
          "https://eloboost-tn.herokuapp.com/api/offreDetail/60652845621cd500151a5b34",
          this.offreDetails
        )
        .then((response) => {
          this.$toast.success("Offre updated");
          this.offreDetails = response.data.offreDetails;
        })
        .catch((error) => {
          this.$toast.error("error during updating");
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style></style>
