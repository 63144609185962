<template>
  <div>
    <base-header
      type="gradient-success"
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    >
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">New Game</h3>
                </div>
                <div class="col-4 text-right">
                  <button
                    href="#!"
                    style="
                      cursor: pointer;
                      color: #fff !important;
                      background: #ff0010;
                    "
                    class="btn btn-md"
                    @click="addGame()"
                     :disabled="disabled == 0"
                    >Add</button
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Game information</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Title"
                        placeholder="title"
                        input-classes="form-control-alternative"
                        v-model="model.title"
                        @input="buttonVerification(model.title)"
                      />
                    </div>
                    <div class="col-lg-6">
                      <slot name="label">
                        <label class="form-control-label"> Date </label>
                      </slot>

                      <input
                        type="date"
                        class="form-control"
                        v-model="model.date"
                        @input="buttonVerification(model.date)"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <input
                        type="file"
                        class="form-control"
                        @click="openUploadModal"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
    <br />
    <br />

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <game-table title="Games"></game-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GameTable from "./Tables/gameTable.vue";
import axios from "axios";
import {checkValue} from "../utils/utils.js";

export default {
  name: "game",
  components: {
    GameTable,
  },
  data() {
    return {
      disabled: 0,
      model: {
        title: "",
        date: null,
        img: "",
      },
    };
  },
  methods: {
    buttonVerification(value){
      this.disabled=checkValue(value,'')
    },
    openUploadModal(e) {
      e.preventDefault();
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "eloBoost", upload_preset: "we3x3upp" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.model.img = this.publicId;
            }
          }
        )
        .open();
    },
    addGame() {
      console.log(this.model);
      axios
        .post("https://eloboost-tn.herokuapp.com/api/offre", this.model)
        .then((response) => {
          this.$toast.open({
            message: "New game",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
        })
        .catch((error) => {
          console.error("There was an error!", error.response.data);
          this.$toast.open({
            message: "Error during the add",
            type: "error",
            position: "bottom-right",
            // all of other options may go here
          });
        });
    },
  },
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
</style>
