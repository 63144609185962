<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
      style="background-color: #1da1f2 !important"
    >
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <requests-table title="Coach & booster Requests"></requests-table>
        </div>
      </div>
    </div>
    <!--   <br />
    <br />

 <div class="container-fluid">
      <div class="row">
        <div class="col">
          <requests-table
            messages="i want to boost some noobs 😎 "
            title="Booster Requests"
          ></requests-table>
        </div>
      </div>
    </div>
    <br />
    <br />

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <requests-table
            messages="i want to boost some noobs 😎 "
            title="Booster & b Requests"
          ></requests-table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import RequestsTable from "./Tables/requestsTable";
export default {
  name: "requests",
  components: {
    RequestsTable,
  },
};
</script>
<style></style>
