<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Users"
            type="gradient-red"
            :sub-title="totalUsers"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                            <span class="text-nowrap">Since last month</span>
                        </template> -->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total boosters"
            type="gradient-orange"
            :sub-title="totalBoosters"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Coaches"
            type="gradient-green"
            :sub-title="totalCoach"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total B & C"
            type="gradient-info"
            :sub-title="totalCoachAndBooster"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table-user
            :users="users"
            title="Utilisateur"
          ></projects-table-user>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <projects-table-user
            :users="coaches"
            title="Coach"
          ></projects-table-user>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <projects-table-user
            :users="boosters"
            title="Boosters"
          ></projects-table-user>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <projects-table-user
            :users="coachesAndBoosters"
            title="Coaches and Boosters"
          ></projects-table-user>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <projects-table-user
            :users="unverified"
            title="Unverified"
          ></projects-table-user>
        </div>
      </div>
      <!-- <div class="row mt-5">
                <div class="col">
                    <projects-table type="dark" title="Dark Table"></projects-table>
                </div>
            </div>
              <div class="row mt-5">
                <div class="col">
                    <projects-table title="Light Table"></projects-table>
                </div>
            </div> -->
    </div>
  </div>
</template>
<script>
import ProjectsTableUser from "./Tables/ProjectsTableUser";
import axios from "axios";
export default {
  name: "tables",
  components: {
    ProjectsTableUser,
  },
  data() {
    return {
      totalUsers: "0",
      totalBoosters: "0",
      totalCoach: "0",
      totalCoachAndBooster: "0",
      users: [],
      coaches: [],
      boosters: [],
      coachesAndBoosters: [],
      unverified: [],
    };
  },
  mounted() {
    return new Promise(() => {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/user/getAll")
        .then((response) => {
          console.log(response);
          this.users = response.data.users.filter((u) => u.state === 1);
          this.boosters = response.data.users.filter((u) => u.state === 2);
          this.coaches = response.data.users.filter((u) => u.state === 3);
          this.coachesAndBoosters = response.data.users.filter(
            (u) => u.state === 4
          );
          this.unverified = response.data.users.filter((u) => u.state === 0);
          this.totalUsers = (response.data.users.length - 1).toString();
          this.totalCoach = this.coaches.length.toString();
          this.totalBoosters = this.boosters.length.toString();
          this.totalCoachAndBooster = this.coachesAndBoosters.length.toString();
        });
    });
  },
};
</script>
<style></style>
