<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive table-padding">
      <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
        :pagination-options="{
          enabled: true,
          perPage: 5,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
          class="table-added-element"
        >
          <span v-if="props.column.field === 'action'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  v-if="props.row.state != 1"
                  class="dropdown-item"
                  href="#"
                  @click="
                    (modal = true),
                      (rowId = props.row._id),
                      (userId = props.row.user._id)
                  "
                  >Accept</a
                >
                <!-- @click="orderStat(row._id, row.user._id, 1)" -->
                <a
                  v-if="props.row.state != 2"
                  class="dropdown-item"
                  href="#"
                  @click="orderStat(props.row._id, props.row.user._id, 2)"
                  >Decline</a
                >
                <a
                  v-if="props.row.state != 3"
                  class="dropdown-item"
                  href="#"
                  @click="orderStat(props.row._id, props.row.user._id, 3)"
                  >Finish</a
                >
              </template>
            </base-dropdown>
          </span>
          <span v-else-if="props.column.field === 'cv'">
            <a :href="getImg(props.row.cv)" target="_blank"
              ><img :src="getImg(props.row.cv)" class="size-img"
            /></a>
          </span>

     <span v-else-if="props.column.field === 'description'">
          <p class="section-height">{{props.row.description}}</p>
          </span>

               <span v-else-if="props.column.field === 'achivements'">
          <p class="section-height">{{props.row.achivements}}</p>
          </span>


          <span v-else-if="props.column.field === 'demande'">
            <div class="section-height">
 <table
              v-for="(element, index) in props.row.demande"
              :key="index"
              class="subTable-request"
            >
              <td>{{ getDateFormat(element.createdAt) }}</td>
              <td>{{ element.name }}</td>
              <td>{{ getState(element.state) }}</td>
              <td>{{ getResult(element.status) }}</td>
              <td class="text-right">
                <base-dropdown class="dropdown" position="right">
                  <a
                    slot="title"
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </a>

                  <template>
                    <a
                      v-if="element.status != 1"
                      class="dropdown-item"
                      href="#"
                      @click="acceptRequest(element, props.row)"
                      >Accept request</a
                    >
                    <a
                      v-if="element.status != 2"
                      class="dropdown-item"
                      href="#"
                      @click="declineRequest(element, props.row)"
                      >Decline request</a
                    >
                  </template>
                </base-dropdown>
              </td>
            </table>
            </div>
           
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!--            <base-pagination total="30"></base-pagination>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "requests-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    messages: String,
  },
  data() {
    return {
      tableData: [],
      columns: [
        {
          label: "Full Name",
          field: "userName",
        },
        {
          label: "Email",
          field: "email",
        },
        // {
        //   label: "Date",
        //   field: "createdAt",
        //   formatFn: this.getDateFormat,
        // },
        {
          label: "country",
          field: "country",
        },
        {
          label: "Current rank",
          field: "currentRank",
        },
        {
          label: "max rank",
          field: "maxRank",
        },
        {
          label: "Languages",
          field: "languages",
        },
        {
          label: "Discord",
          field: "discord",
        },
        {
          label: "C.V",
          field: "cv",
        },
        {
          label: "Achivements",
          field: "achivements",
          width: "250px",
        },
        {
          label: "Description",
          field: "description",
          width: "250px",
        },

        {
          label: "Game",
          field: "demande",
        },
      ],
      rows: [],
    };
  },
  created() {
    this.getAllRequest();
  },
  methods: {
    getDateFormat(date) {
      return new Date(date).toLocaleString();
    },
    getAllRequest() {
      this.tableData = [];
      axios
        .get("https://eloboost-tn.herokuapp.com/api/user/getAll")
        .then((response) => {
          response.data.users.forEach((element) => {
            if (element.demande.length > 0) {
              this.tableData.push(element);
            }
          });
          this.rows = this.tableData;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    acceptRequest(element, row) {
      element.status = 1;
      row.state = element.state;
      console.log("row", row);
      axios
        .post("https://eloboost-tn.herokuapp.com/api/user/updateDemande", row)
        .then((response) => {
          this.$toast.open({
            message: "Request accepted",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
          this.getAllRequest();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    declineRequest(element, row) {
      element.status = 2;
      axios
        .post("https://eloboost-tn.herokuapp.com/api/user/updateDemande", row)
        .then((response) => {
          this.$toast.open({
            message: "Request declined",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
          this.getAllRequest();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getState(type) {
      if (type == 2) {
        return "booster";
      } else if (type == 3) {
        return "coach";
      } else {
        return "coach & booster";
      }
    },
    getResult(res) {
      if (res == 2) {
        return "Declined";
      } else if (res == 1) {
        return "Accepted";
      } else {
        return "Pending";
      }
    },
    getImg(img) {
      if (img)
        return (
          "https://res.cloudinary.com/eloboost/image/upload/v1616982976/" + img
        );
    },
  },
};
</script>
<style>
.size-p {
  height: 60px;
  width: 250px;
  overflow: auto;
}
.size-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.subTable-request td {
  width: 100%;
}
.section-height{
  height: 100px;
  overflow: auto;
}
</style>
