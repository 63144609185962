<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <!-- <div class="row">
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Nombre des Articles"
                                type="gradient-red"
                                sub-title="99"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0">
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Nombre des commentaires"
                                type="gradient-orange"
                                sub-title="99"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0">
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Nombre des reponses"
                                type="gradient-green"
                                sub-title="99"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0">
                    </stats-card>

                </div>
            </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-md-4">
          <router-link to="./LeagueOfLegends">
            <card title="Email" class="img-games-offre">
              <img src="../../public/img/1571178657359.webp" />
            </card>
          </router-link>
        </div>
        <div class="col-md-4">
          <router-link to="./tft">
            <card title="Email" class="img-games-offre">
              <img src="../../public/img/TFT.S3_GALAXIES.ARTICLE-2.jpg" />
            </card>
          </router-link>
        </div>
        <div class="col-md-4">
          <router-link to="./valo">
            <card title="Email" class="img-games-offre">
              <img src="../../public/img/valorant-1280x720.jpg" />
            </card>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tables",
  components: {},
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
.img-games-offre {
  width: 100%;
  height: 350px;
  overflow: hidden;
}
.img-games-offre:hover {
  height: 400px;
}
.img-games-offre img {
  width: 100%;
  height: 100%;
}
</style>
