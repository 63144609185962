<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
      style="background-color: #1da1f2 !important"
    >
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table-valo title="Valorant"></projects-table-valo>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <projects-table-valo-option
            title="League of legends option"
          ></projects-table-valo-option>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsTableValo from "./Tables/ProjectsTableValo";
import ProjectsTableValoOption from "./Tables/ProjectsTableValoOption";

export default {
  name: "tables",
  components: {
    ProjectsTableValo,
    ProjectsTableValoOption,
  },
};
</script>
<style></style>
