<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            style="background: #ed3029; border: none"
            size="md"
            @click="priceDivisionUpdate"
            >Save</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <tabs fill class="flex-column flex-md-row">
        <card shadow>
          <tab-pane icon="ni ni-cloud-upload-96" title="Iron">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataIron"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[0].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 3"
                    v-model="priceDivision.price.d3"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 2"
                    v-model="priceDivision.iron.d2"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 1"
                    v-model="priceDivision.iron.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-bell-55 mr-2" title="Bronze">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataBronze"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[1].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 3"
                    v-model="priceDivision.bronze.d3"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 2"
                    v-model="priceDivision.bronze.d2"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 1"
                    v-model="priceDivision.bronze.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Silver">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataSilver"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>
                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[2].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 3"
                    v-model="priceDivision.silver.d3"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 2"
                    v-model="priceDivision.silver.d2"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 1"
                    v-model="priceDivision.silver.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Gold">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataGold"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[3].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 3"
                    v-model="priceDivision.gold.d3"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 2"
                    v-model="priceDivision.gold.d2"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 1"
                    v-model="priceDivision.gold.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Platinum">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataPlatinum"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[4].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 3"
                    v-model="priceDivision.platinum.d3"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 2"
                    v-model="priceDivision.platinum.d2"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 1"
                    v-model="priceDivision.platinum.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Diamond">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataDiamond"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[5].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 3"
                    v-model="priceDivision.diamond.d3"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 2"
                    v-model="priceDivision.diamond.d2"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-if="row.number == 1"
                    v-model="priceDivision.diamond.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Master">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataMaster"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[6].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-model="priceDivision.master.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <tab-pane icon="ni ni-calendar-grid-58" title="Grandmaster">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataGrandmaster"
            >
              <template slot="columns">
                <th>From</th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="
                      offreDetails.priceDivision[7].price[row.number].price
                    "
                  />
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-model="priceDivision.grandmaster.d1"
                  /> -->
                </td>
              </template>
            </base-table>
          </tab-pane>
          <!-- <tab-pane icon="ni ni-calendar-grid-58" title="Challenger">
            <base-table
              class="table align-items-center table-flush"
              :class="type === 'dark' ? 'table-dark' : ''"
              :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
              tbody-classes="list"
              :data="tableDataChallenger"
            >
              <template slot="columns">
                <th>From</th>
                <th></th>
                <th>To</th>
                <th>Prix</th>
              </template>

              <template slot-scope="{ row }">
                <th scope="row">
                  <img
                    alt="Image placeholder"
                    :src="row.from"
                    style="width: 30%"
                  />
                  {{ row.fromTitle }}
                </th>
                <td class="budget">
                  <img src="img/rankBadges/arrow.png" style="width: 100px" />
                </td>

                <td>
                  <img
                    alt="Image placeholder"
                    :src="row.to"
                    style="width: 30%"
                  />
                  {{ row.toTitle }}
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="priceDivision[0].price[row.number].price"
                  />
                
                </td>
              </template>
            </base-table>
          </tab-pane> -->
        </card>
      </tabs>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "projects-table-tft",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      offreDetails: {},
      priceDivision: [
        {
          name: "Iron",
          divisionNumber: 0,
          price: [
            {
              name: "Iron4",
              price: 0,
              nbPrice: 0,
            },
            {
              name: "Iron3",
              price: 0,
              nbPrice: 1,
            },
            {
              name: "Iron2",
              price: 0,
              nbPrice: 2,
            },
            {
              name: "Iron",
              price: 0,
              nbPrice: 3,
            },
          ],
        },
        {
          name: "Bronze",
          divisionNumber: 1,
          price: [
            {
              name: "Bronze4",
              price: 0,
              nbPrice: 4,
            },
            {
              name: "Bronze3",
              price: 0,
              nbPrice: 5,
            },
            {
              name: "Bronze2",
              price: 0,
              nbPrice: 6,
            },
            {
              name: "Bronze",
              price: 0,
              nbPrice: 7,
            },
          ],
        },
        {
          name: "Silver",
          price: [
            {
              name: "Silver3",
              price: 0,
              nbPrice: 8,
            },
            {
              name: "Silver2",
              price: 0,
              nbPrice: 9,
            },
            {
              name: "Silver1",
              price: 0,
              nbPrice: 10,
            },
            {
              name: "Silver0",
              price: 0,
              nbPrice: 11,
            },
          ],
        },
        {
          name: "Gold",
          price: [
            {
              name: "Gold3",
              price: 0,
              nbPrice: 12,
            },
            {
              name: "Gold2",
              price: 0,
              nbPrice: 13,
            },
            {
              name: "Gold1",
              price: 0,
              nbPrice: 14,
            },
            {
              name: "Gold0",
              price: 0,
              nbPrice: 15,
            },
          ],
        },
        {
          name: "Platinum",
          price: [
            {
              name: "Platinum3",
              price: 0,
              nbPrice: 16,
            },
            {
              name: "Platinum2",
              price: 0,
              nbPrice: 17,
            },
            {
              name: "Platinum1",
              price: 0,
              nbPrice: 18,
            },
            {
              name: "Platinum0",
              price: 0,
              nbPrice: 19,
            },
          ],
        },
        {
          name: "Diamond",
          price: [
            {
              name: "Diamond3",
              price: 0,
              nbPrice: 20,
            },
            {
              name: "Diamond2",
              price: 0,
              nbPrice: 21,
            },
            {
              name: "Diamond1",
              price: 0,
              nbPrice: 22,
            },
            {
              name: "Diamond0",
              price: 0,
              nbPrice: 23,
            },
          ],
        },
        {
          name: "Master",
          price: [
            {
              name: "Master",
              price: 0,
              nbPrice: 24,
            },
            {
              name: "Master0",
              price: 0,
              nbPrice: 25,
            },
          ],
        },
        {
          name: "Grandmaster",
          price: [
            {
              name: "Grandmaster",
              price: 0,
              nbPrice: 26,
            },
            {
              name: "Grandmaster0",
              price: 0,
              nbPrice: 27,
            },
          ],
        },
        {
          name: "Challenger",
          price: [
            {
              name: "Challenger",
              price: 0,
              nbPrice: 28,
            },
          ],
        },
      ],
      tableDataIron: [
        {
          from: "img/rankBadges/Season_2019_-_Iron_4.png",
          to: "img/rankBadges/Season_2019_-_Iron_3.png",
          fromTitle: "Iron 4",
          toTitle: "Iron 3",
          number: 0,
        },
        {
          from: "img/rankBadges/Season_2019_-_Iron_3.png",
          to: "img/rankBadges/Season_2019_-_Iron_2.png",
          fromTitle: "Iron 3",
          toTitle: "Iron 2",
          number: 1,
        },
        {
          from: "img/rankBadges/Season_2019_-_Iron_2.png",
          to: "img/rankBadges/Season_2019_-_Iron_1.png",
          fromTitle: "Iron 2",
          toTitle: "Iron 1",
          number: 2,
        },
        {
          from: "img/rankBadges/Season_2019_-_Iron_1.png",
          to: "img/rankBadges/Season_2019_-_Bronze_4.png",
          fromTitle: "Iron 1",
          toTitle: "Bronze 4",
          number: 3,
        },
      ],
      tableDataBronze: [
        {
          from: "img/rankBadges/Season_2019_-_Bronze_4.png",
          to: "img/rankBadges/Season_2019_-_Bronze_3.png",
          fromTitle: "Bronze 4",
          toTitle: "Bronze 3",
          number: 0,
        },
        {
          from: "img/rankBadges/Season_2019_-_Bronze_3.png",
          to: "img/rankBadges/Season_2019_-_Bronze_2.png",
          fromTitle: "Bronze 3",
          toTitle: "Bronze 2",
          number: 1,
        },
        {
          from: "img/rankBadges/Season_2019_-_Bronze_2.png",
          to: "img/rankBadges/Season_2019_-_Bronze_1.png",
          fromTitle: "Bronze 2",
          toTitle: "Bronze 1",
          number: 2,
        },
        {
          from: "img/rankBadges/Season_2019_-_Bronze_1.png",
          to: "img/rankBadges/Season_2019_-_Silver_4.png",
          fromTitle: "Bronze 1",
          toTitle: "Silver 4",
          number: 3,
        },
      ],
      tableDataSilver: [
        {
          from: "img/rankBadges/Season_2019_-_Silver_4.png",
          to: "img/rankBadges/Season_2019_-_Silver_3.png",
          fromTitle: "Silver 4",
          toTitle: "Silver 3",
          number: 0,
        },
        {
          from: "img/rankBadges/Season_2019_-_Silver_3.png",
          to: "img/rankBadges/Season_2019_-_Silver_2.png",
          fromTitle: "Silver 3",
          toTitle: "Silver 2",
          number: 1,
        },
        {
          from: "img/rankBadges/Season_2019_-_Silver_2.png",
          to: "img/rankBadges/Season_2019_-_Silver_1.png",
          fromTitle: "Silver 2",
          toTitle: "Silver 1",
          number: 2,
        },
        {
          from: "img/rankBadges/Season_2019_-_Silver_1.png",
          to: "img/rankBadges/Season_2019_-_Gold_4.png",
          fromTitle: "Silver 1",
          toTitle: "Gold 4",
          number: 3,
        },
      ],
      tableDataGold: [
        {
          from: "img/rankBadges/Season_2019_-_Gold_4.png",
          to: "img/rankBadges/Season_2019_-_Gold_3.png",
          fromTitle: "Gold 4",
          toTitle: "Gold 3",
          number: 0,
        },
        {
          from: "img/rankBadges/Season_2019_-_Gold_3.png",
          to: "img/rankBadges/Season_2019_-_Gold_2.png",
          fromTitle: "Gold 3",
          toTitle: "Gold 2",
          number: 1,
        },
        {
          from: "img/rankBadges/Season_2019_-_Gold_2.png",
          to: "img/rankBadges/Season_2019_-_Gold_1.png",
          fromTitle: "Gold 2",
          toTitle: "Gold 1",
          number: 2,
        },
        {
          from: "img/rankBadges/Season_2019_-_Gold_1.png",
          to: "img/rankBadges/Season_2019_-_Platinum_4.png",
          fromTitle: "Gold 1",
          toTitle: "Platinum 4",
          number: 3,
        },
      ],
      tableDataPlatinum: [
        {
          from: "img/rankBadges/Season_2019_-_Platinum_4.png",
          to: "img/rankBadges/Season_2019_-_Platinum_3.png",
          fromTitle: "Platinum 4",
          toTitle: "Platinum 3",
          number: 0,
        },
        {
          from: "img/rankBadges/Season_2019_-_Platinum_3.png",
          to: "img/rankBadges/Season_2019_-_Platinum_2.png",
          fromTitle: "Platinum 3",
          toTitle: "Platinum 2",
          number: 1,
        },
        {
          from: "img/rankBadges/Season_2019_-_Platinum_2.png",
          to: "img/rankBadges/Season_2019_-_Platinum_1.png",
          fromTitle: "Platinum 2",
          toTitle: "Platinum 1",
          number: 2,
        },
        {
          from: "img/rankBadges/Season_2019_-_Platinum_1.png",
          to: "img/rankBadges/Season_2019_-_Diamond_4.png",
          fromTitle: "Platinum 1",
          toTitle: "Diamond 4",
          number: 3,
        },
      ],
      tableDataDiamond: [
        {
          from: "img/rankBadges/Season_2019_-_Diamond_4.png",
          to: "img/rankBadges/Season_2019_-_Diamond_3.png",
          fromTitle: "Diamond 4",
          toTitle: "Diamond 3",
          number: 0,
        },
        {
          from: "img/rankBadges/Season_2019_-_Diamond_3.png",
          to: "img/rankBadges/Season_2019_-_Diamond_2.png",
          fromTitle: "Diamond 3",
          toTitle: "Diamond 2",
          number: 1,
        },
        {
          from: "img/rankBadges/Season_2019_-_Diamond_2.png",
          to: "img/rankBadges/Season_2019_-_Diamond_1.png",
          fromTitle: "Diamond 2",
          toTitle: "Diamond 1",
          number: 2,
        },
        {
          from: "img/rankBadges/Season_2019_-_Diamond_1.png",
          to: "img/rankBadges/Season_2019_-_Master_4.png",
          fromTitle: "Diamond 1",
          toTitle: "Master 4",
          number: 3,
        },
      ],
      tableDataMaster: [
        {
          from: "img/rankBadges/Season_2019_-_Master_1.png",
          to: "img/rankBadges/Season_2019_-_Grandmaster_1.png",
          fromTitle: "Master",
          toTitle: "Grandmaster",
          number: 0,
        },
      ],
      tableDataGrandmaster: [
        {
          from: "img/rankBadges/Season_2019_-_Grandmaster_1.png",
          to: "img/rankBadges/Season_2019_-_Challenger_1.png",
          fromTitle: "Grandmaster",
          toTitle: "Challenger",
          number: 0,
        },
      ],
      // tableDataChallenger: [
      //   {
      //     from: "img/rankBadges/Season_2019_-_Grandmaster_1.png",
      //     to: "img/rankBadges/Season_2019_-_Challenger_1.png",
      //     fromTitle: "Challenger",
      //     toTitle: "Challenger",
      //     number: 0,
      //   },
      // ],
    };
  },
  created() {
    this.getOffre();
  },
  methods: {
    getOffre() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/offreDetail/")
        .then((response) => {
          this.offreDetails = response.data.offreDetails[2];
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    priceDivisionUpdate() {
      axios
        .put(
          "https://eloboost-tn.herokuapp.com/api/offreDetail/606529cf03a68c0015ef5ac4",
          this.offreDetails
        )
        .then((response) => {
          this.$toast.success("Offre updated");
          this.offreDetails = response.data.offreDetails;
        })
        .catch((error) => {
          this.$toast.error("error during updating");
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style></style>
