<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" action="" method="" v-on:keyup.enter="login">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="login"
                >Sign in</base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      console.log(this.model);
      axios
        .post("https://eloboost-tn.herokuapp.com/api/user/login", this.model)
        .then((response) => {
          if (response.data.user.state === 999) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("UId", response.data.user._id);
            this.$store.commit("setUserInfo", response.data.user);
            this.$toast.open({
              message: "Welcome",
              type: "success",
              position: "bottom-right",
              // all of other options may go here
            });
            this.$router.push({ name: "Users" });
          } else {
            this.$toast.open({
              message: "Email ou Mot de passe est incorrect",
              type: "error",
              position: "bottom-right",
              // all of other options may go here
            });
          }
        })
        .catch((error) => {
          console.error("There was an error!", error.response.data);
          this.$toast.open({
            message: "Email ou Mot de passe est incorrect",
            type: "error",
            position: "bottom-right",
            // all of other options may go here
          });
        });
    },
  },
};
</script>
<style>
</style>
