
export const checkValue = (value1, value2) => {
    var disabled=null
    if (value1 != value2) {
        disabled = 1
    } else {
        disabled = 0
    }
    return disabled
}
