<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            style="background: #ed3029; border: none"
            size="md"
            @click="optionDivision"
            >Save</base-button
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableDataOption"
      >
        <template slot="columns">
          <th>name</th>
          <th>Prix</th>
        </template>

        <template slot-scope="{ row }">
          <td>
            {{ row.option }}
          </td>

          <td>
            <input
              type="text"
              class="form-control"
              v-if="row.number == 1"
              v-model="model.priceDivisionOption.o1"
            />
            <input
              type="text"
              class="form-control"
              v-if="row.number == 2"
              v-model="model.priceDivisionOption.o2"
            />
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "projects-table-lol-option",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      model: {
        gameName: "lol",
        priceDivisionOption: {
          o1: 0,
          o2: 0,
        },
      },
      tableDataOption: [
        {
          option: "Priority Order",
          number: 1,
        },
        {
          option: "main champ",
          number: 2,
        },
      ],
    };
  },
  created() {
    this.getOffre();
  },
  methods: {
    getOffre() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/gamesPriceOptions/")
        .then((response) => {
          console.log("option", response);
          this.model.priceDivisionOption =
            response.data.gamesPriceOptions[0].priceDivisionOption;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    optionDivision() {
      console.log({
        gameName: "lol",
        newData: this.model.priceDivisionOption,
      });
      axios
        .put("https://eloboost-tn.herokuapp.com/api/gamesPriceOptions", {
          gameName: "lol",
          newData: { priceDivisionOption: this.model.priceDivisionOption },
        })
        .then((response) => {
          console.log("option", response);
          this.$toast.open({
            message: "League of legends option updated",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
          // this.priceDivisionOption = response.data.offre;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$toast.open({
            message: "Error",
            type: "error",
            position: "bottom-right",
            // all of other options may go here
          });
        });
    },
  },
};
</script>
<style></style>
