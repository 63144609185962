<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
      style="background-color: #1da1f2 !important"
    >
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table-tft title="TFT"></projects-table-tft>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <projects-table-tft-option
            title="League of legends option"
          ></projects-table-tft-option>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsTableTft from "./Tables/ProjectsTableTft";
import ProjectsTableTftOption from "./Tables/ProjectsTableTftOption";

export default {
  name: "tables",
  components: {
    ProjectsTableTft,
    ProjectsTableTftOption,
  },
};
</script>
<style></style>
