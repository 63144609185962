<template>
    <div >
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" style="background-color: #1da1f2 !important;" >
            <!-- Card stats -->

        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <contact-table title="Contact"></contact-table>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import ContactTable from './Tables/contactTable'
    export default {
        name: 'tables',
        components: {
            ContactTable
        }
    };
</script>
<style></style>
