<template>
  <div class="chatPage">
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
    </base-header>
    <div class="container-fluid mt--7">
      <chat-window
        v-if="userId"
        :current-user-id="userId"
        :rooms="rooms"
        :messages="messages"
        :loading-rooms="loadingRooms"
        :rooms-loaded="true"
        @send-message="sendMessage"
        @fetch-messages="getRoomInfo"
        :messages-loaded="messagesloaded"
        @send-message-reaction="sendReaction"
        @open-file="openFile"
      />
      <!-- @edit-message="editMessage" -->
      <!-- @delete-message="deleteMessage" -->
    </div>
    <modal :show.sync="showModal" v-if="showModal" class="imgModal">
      <img :src="activeImage" />
    </modal>
  </div>
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import axios from "axios";
export default {
  components: {
    ChatWindow,
  },
  data() {
    return {
      rooms: [],
      messages: [],
      messagesloaded: false,
      roomId: null,
      otherUser: null,
      loadingRooms: true,
      showModal: false,
      activeImage: "",
    };
  },
  computed: {
    userId() {
      return localStorage.getItem("UId").toString();
    },
  },
  methods: {
    getRoomInfo({ room }) {
      this.roomId = room.roomId;
      this.messagesloaded = false;
      this.getMessages(room.roomId);
    },

    getMessages(roomId) {
      try {
        if (roomId) {
          axios
            .get(
              "https://eloboost-tn.herokuapp.com/api/messages/room/" + roomId
            )
            .then((response) => {
              this.messages = response.data.messages.map((message) => ({
                ...message,
                roomId: message.roomId._id,
              }));
            })
            .catch((error) => {
              console.error("There was an error!", error.response.data);
            });
          this.messagesloaded = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getRooms() {
      try {
        const res = await axios.get(
          "https://eloboost-tn.herokuapp.com/api/chatRooms/" +
            localStorage.getItem("UId")
        );
        this.loadingRooms = false;
        this.rooms = res.data.chatRooms.map((room) => ({
          ...room,
          users: this.getRoomUsers(room),
          roomId: room._id,
          roomName: this.getRoomName(room),
          avatar: this.getOtherUserAvatar(room),
        }));
      } catch (error) {
        console.log(error);
        this.loadingRooms = false;
      }
    },

    getOtherUser(users) {
      return users.find((user) => user._id != this.userId);
    },

    getOtherUserAvatar({ users }) {
      const otherUser = this.getOtherUser(users);
      return !otherUser.photo
        ? "https://res.cloudinary.com/eloboost/image/upload/v1617048577/t%C3%A9l%C3%A9chargement_yo46pz_cse8su.jpg"
        : `https://res.cloudinary.com/eloboost/image/upload/v1617048577/${otherUser.photo}`;
    },

    getRoomName({ users }) {
      const otherUser = this.getOtherUser(users);
      return `${otherUser.userName || ""}`;
    },

    getRoomUsers({ users }) {
      return users.map((user) => ({
        username: `${user.userName}`,
        ...user,
      }));
    },

    async sendMessage(message) {
      console.log("message", message);
      try {
        message.sender_id = this.userId;
        if (message.file) {
          message.file.url = await this.uploadFile(message.file);
        }
        const res = await axios.post(
          "https://eloboost-tn.herokuapp.com/api/messages/",
          message
        );
        this.messages = [...this.messages, res.data.messageToSend];
      } catch (err) {
        console.log(err);
      }
    },
    // "vue-advanced-chat": "^0.8.3",

    async sendReaction({ roomId, messageId, reaction, remove }) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.reactions = message.reactions
        ? { ...message.reactions, [reaction.name]: [this.userId] }
        : { [reaction.name]: [this.userId] };
      try {
        await axios
          .put(
            "https://eloboost-tn.herokuapp.com/api/messages/message/" +
              messageId,
            { reactions: message.reactions }
          )
          .then((response) => {})
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });

        // await this.$store.dispatch("admin/sendReaction", {
        //   messageId,
        //   reactions: message.reactions,
        // });
      } catch (err) {
        console.log(err);
      }
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
      console.log(this.messages);
    },

    // async editMessage({ messageId, newContent }) {
    //   try {

    //     await axios
    //       .put(
    //         "https://eloboost-tn.herokuapp.com/api/messages/message/" +
    //           messageId,
    //         { message: { content: newContent } }
    //       )
    //       .then((response) => {
    //         this.editLocalMessage(messageId, newContent);
    //       })
    //       .catch((error) => {
    //         console.error("There was an error!", error.response.data);
    //       });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },

    // editLocalMessage(messageId, newContent) {
    //   let messageIndex = this.messages.findIndex(
    //     (message) => message._id == messageId
    //   );
    //   let message = this.messages[messageIndex];
    //   message.content = newContent;
    //   this.messages[messageIndex] = message;
    //   this.messages = [...this.messages];
    // },

    // async deleteMessage({ messageId }) {
    //   try {

    //     await axios
    //       .put(
    //         "https://eloboost-tn.herokuapp.com/api/messages/message/" +
    //           messageId
    //       )
    //       .then((response) => {
    //         this.deleteLocalMessage(messageId);
    //       })
    //       .catch((error) => {
    //         console.error("There was an error!", error.response.data);
    //       });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },

    // deleteLocalMessage(messageId) {
    //   let index = this.messages.findIndex(
    //     (message) => message._id.toString() == messageId
    //   );
    //   this.messages.splice(index, 1);
    //   this.messages = [...this.messages];
    // },

    async uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.blob);
      formData.append("upload_preset", "we3x3upp");
      return await axios
        .post(`https://api.cloudinary.com/v1_1/eloboost/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.secure_url;
        })
        .catch((err) => console.log(err));
    },

    openFile({ message, action }) {
      switch (action) {
        case "preview":
          // const win = window.open(message.file.url, "_blank");
          // win.focus();
          this.showModal = true;
          this.activeImage = message.file.url;
          break;
        case "download":
          axios
            .get(message.file.url, {
              responseType: "blob",
            })
            .then((res) => {
              const url = window.URL.createObjectURL(res.data);
              const a = document.createElement("a");
              a.href = url;
              a.download = message.file.name;
              a.click();
            })
            .catch((err) => console.log(err));
      }
    },
  },

  async mounted() {
    await this.getRooms();
    setInterval(() => {
      this.getMessages(this.roomId);
    }, 5000);
  },
};
</script>

<style lang="scss">
.vac-room-header {
  z-index: 0 !important;
}
.chatPage .modal .modal-dialog {
  justify-content: center;
  .modal-content {
    height: auto;
    width: auto;
    .modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 80vh;
      }
    }
  }
}
</style>