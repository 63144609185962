<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive table-padding">
      <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
        :pagination-options="{
          enabled: true,
          perPage: 5,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
          class="table-added-element"
        >
          <span v-if="props.column.field === 'action'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  v-if="props.row.state != 1"
                  class="dropdown-item"
                  href="#"
                  @click="
                    (modal = true),
                      (rowId = props.row._id),
                      (userId = props.row.user._id)
                  "
                  >Accept</a
                >
                <!-- @click="orderStat(row._id, row.user._id, 1)" -->
                <a
                  v-if="props.row.state != 2"
                  class="dropdown-item"
                  href="#"
                  @click="orderStat(props.row._id, props.row.user._id, 2)"
                  >Decline</a
                >
                <a
                  v-if="props.row.state != 3"
                  class="dropdown-item"
                  href="#"
                  @click="orderStat(props.row._id, props.row.user._id, 3)"
                  >Finish</a
                >
              </template>
            </base-dropdown>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!--            <base-pagination total="30"></base-pagination>-->
    </div>
    <modal :show.sync="modal">
      <div>
        <label>Select Booster</label>
        <select
          name=""
          id=""
          v-model="updateOrder.booster"
          class="form-control"
        >
          <option
            :value="element._id"
            v-for="(element, index) in tableUsers"
            :key="index"
          >
            {{ element.userName }}
          </option>
        </select>
      </div>
      <template v-slot:footer>
        <base-button type="secondary" @click="modal = false"
          >Fermer</base-button
        >
        <base-button
          type="primary"
          @click="orderStat(rowId, userId, 1), (modal = false)"
          >Accept</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "orders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    messages: String,
  },
  data() {
    return {
      columns: [
        {
          label: "Full Name",
          field: "user.userName",
        },
        {
          label: "Email",
          field: "user.email",
        },
        {
          label: "Coach Name",
          field: "coach.userName",
        },
        {
          label: "Coach Email",
          field: "coach.email",
        },
        {
          label: "Hours",
          field: "hours",
        },
        {
          label: "price",
          field: "coach.pricePerHour",
        },
        {
          label: "Date",
          field: "createdAt",
          formatFn: this.getDateFormat,
        },
        {
          label: "state",
          field: "state",
          formatFn: this.getResult,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "100px",
        },
      ],
      rows: [],

      rowId: null,
      userId: null,
      modal: false,
      updateOrder: {
        booster: "",
        state: "",
        // booster: "",
      },
      tableUsers: [],
    };
  },
  created() {
    this.getAllOrder();
    this.getUsers();
  },
  methods: {
    getDateFormat(date) {
      return new Date(date).toLocaleString();
    },
    getAllOrder() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/orderCoach")
        .then((response) => {
          this.rows = response.data.orders;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    getUsers() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/user/getAll")
        .then((response) => {
          console.log("users", response);
          response.data.users.forEach((element) => {
            if (element.state == 2 || element.state == 4)
              this.tableUsers.push(element);
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    orderStat(id, user, type) {
      if (type == 1) {
        var obj = {
          state: 1,
          booster: this.updateOrder.booster,
        };
      } else if (type == 2) {
        var obj = {
          state: 2,
        };
      } else {
        var obj = {
          state: 3,
        };
      }
      axios
        .put("https://eloboost-tn.herokuapp.com/api/orderCoach/" + id, obj)
        .then((response) => {
          this.getAllOrder();
          this.$toast.open({
            message: "Order state updated",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
          if (type == 1) {
            axios
              .post("https://eloboost-tn.herokuapp.com/api/chatRooms", {
                userId: this.updateOrder.booster,
                users: [user, this.updateOrder.booster],
                adminId: localStorage.getItem("UId"),
              })
              .then((response) => {
                this.$toast.open({
                  message: "Chat created",
                  type: "success",
                  position: "bottom-right",
                  // all of other options may go here
                });
              });
          } else if (type == 3) {
            axios
              .put("https://eloboost-tn.herokuapp.com/api/chatRooms/", {
                userId: user,
                boosterId: this.updateOrder.booster,
                adminId: localStorage.getItem("UId"),
                isFinished: true,
              })
              .then((response) => {
                this.$toast.open({
                  message: "Chat created",
                  type: "success",
                  position: "bottom-right",
                  // all of other options may go here
                });
              });
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Error during updating",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
          console.error("There was an error!", error);
        });
    },
    getResult(res) {
      if (res == 2) {
        return "Declined";
      } else if (res == 1) {
        return "Accepted";
      } else if (res == 0) {
        return "Pending";
      } else {
        return "Finished";
      }
    },
    // declineRequest(id) {
    //   axios
    //     .put("https://eloboost-tn.herokuapp.com/api/")
    //     .then((response) => {
    //       this.getAllRequest();
    //     })
    //     .catch((error) => {
    //       this.errorMessage = error.message;
    //       console.error("There was an error!", error);
    //     });
    // },
  },
};
</script>
<style></style>
