<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
      style="background-color: #1da1f2 !important"
    >
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table-lol title="League of legends"></projects-table-lol>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col">
          <projects-table-lol-option
            title="League of legends option"
          ></projects-table-lol-option>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsTableLol from "./Tables/ProjectsTableLol";
import ProjectsTableLolOption from "./Tables/ProjectsTableLolOption";

export default {
  name: "tables",
  components: {
    ProjectsTableLol,
    ProjectsTableLolOption,
  },
};
</script>
<style></style>
