<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive table-padding">
      <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
        :pagination-options="{
          enabled: true,
          perPage: 5,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
          class="table-added-element"
        >
          <span v-if="props.column.field === 'action'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="deleteContact(props.row._id)"
                  >delete</a
                >
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
              </template>
            </base-dropdown>
          </span>
        </template>
      </vue-good-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!--            <base-pagination total="30"></base-pagination>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "contact-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "fullName",
        },
        {
          label: "Email",
          field: "email",
        },

        {
          label: "subject",
          field: "subject",
        },
        {
          label: "Date",
          field: "createdAt",
          formatFn: this.getDateFormat,
        },
        {
          label: "Message",
          field: "message",
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "100px",
        },
      ],
      rows: [],
    };
  },
  mounted() {
    axios
      .get("https://eloboost-tn.herokuapp.com/api/contacts")
      .then((response) => {
        console.log(response);
        this.tableData = response.data;
        this.rows = response.data;
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  methods: {
    getDateFormat(date) {
      return new Date(date).toLocaleString();
    },
    deleteContact(contactId) {
      console.log(contactId);
      return new Promise(() => {
        axios
          .delete("https://eloboost-tn.herokuapp.com/api/contacts/" + contactId)
          .then((response) => {
            console.log(response);
            this.rows = response.data.contacts;
            this.$toast.success("deleted successfully ");
          });
      });
    },
  },
};
</script>
<style></style>
