<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template slot="columns">
          <th>title</th>
          <th>date</th>
          <th>image</th>
          <th></th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            {{ row.title }}
          </th>
          <td class="budget">
            {{ dateFormat(row.date) }}
          </td>

          <td>
            <!-- <img
              alt="Image placeholder"
              :src="getImg(row.img)"
              style="width: 100px; height: 100px"
            /> -->
            <div class="img-games">
              <cld-context cloudName="eloBoost">
                <div>
                  <cld-image :publicId="row.img" />
                </div>
              </cld-context>
            </div>
          </td>
          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" href="#" @click="deleteGame(row._id)"
                  >delete</a
                >
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
              </template>
            </base-dropdown>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!--            <base-pagination total="30"></base-pagination>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";

export default {
  name: "game-table",
  components: {
    CldContext,
    CldImage,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    this.getAllGames();
  },
  methods: {
    getAllGames() {
      axios
        .get("https://eloboost-tn.herokuapp.com/api/offre")
        .then((response) => {
          console.log(response);
          this.tableData = response.data.offres;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    deleteGame(id) {
      axios
        .delete("https://eloboost-tn.herokuapp.com/api/offre/" + id)
        .then((response) => {
          console.log(response);
          this.$toast.open({
            message: "Game deleted",
            type: "success",
            position: "bottom-right",
            // all of other options may go here
          });
          this.getAllGames();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Error during deleting",
            type: "error",
            position: "bottom-right",
            // all of other options may go here
          });
          console.error("There was an error!", error);
        });
    },
    getImg(img) {},
    dateFormat: function (date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>
<style>
.img-games img {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}
</style>
