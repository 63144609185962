<template>
  <div>
    <base-header
      type="gradient-success"
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    >
      <!--   style="min-height: 600px; background-image: url(img/theme/profile-cover.jpg); background-size: cover; background-position: center top;" -->
      <!-- Mask -->
      <!-- Header container -->
      <!-- <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                        <h1 class="display-2 text-white">Hello Jesse</h1>
                        <p class="text-white mt-0 mb-5">This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks</p>
                        <a href="#!" class="btn btn-info">Edit profile</a>
                    </div>
                </div>
            </div> -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">My account</h3>
                </div>
                <div class="col-4 text-right">
                  <button
                    style="
                      cursor: pointer;
                      color: #fff !important;
                      background: #ff0010;
                    "
                    :disabled="disabled == 0"
                    class="btn btn-md"
                    @click="updateAccount"
                    >Save</button
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Admin information</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Username"
                        placeholder="Username"
                        input-classes="form-control-alternative"
                        v-model="model.username"
                        @input="disabled=checkValue(model.username,$store.getters.getUserInfo.username)"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        disabled="true"
                        label="Email address"
                        placeholder="EloBoost@example.com"
                        input-classes="form-control-alternative"
                        v-model="model.email"
                      />
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="First name"
                        placeholder="First name"
                        input-classes="form-control-alternative"
                        v-model="model.firstName"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative"
                        v-model="model.lastName"
                      />
                    </div>
                  </div> -->
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Change Password</h3>
                </div>
                <div class="col-4 text-right">
                  <a
                    style="
                      cursor: pointer;
                      color: #fff !important;
                      background: #ff0010;
                    "
                    class="btn btn-md"
                    @click="changePassword"
                    >Save</a
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Change Password</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="Current Password"
                        placeholder="Current Password"
                        input-classes="form-control-alternative"
                        v-model="model.cpassword"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="New Password"
                        placeholder="New Password"
                        input-classes="form-control-alternative"
                        v-model="model.npassword"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        input-classes="form-control-alternative"
                        v-model="model.confirmPassword"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {checkValue} from "../utils/utils.js";

export default {
  name: "user-profile",
  data() {
    return {
      disabled: 0,
            model: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        cpassword: "",
        npassword: "",
        confirmPassword: "",
      },
    };
  },
  mounted() {
    this.model.username = this.$store.getters.getUserInfo.username;
    this.model.email = this.$store.getters.getUserInfo.email;
    // this.model.firstName = this.$store.getters.getUserInfo.firstName;
    // this.model.lastName = this.$store.getters.getUserInfo.lastName;
    // this.model.address = this.$store.getters.getUserInfo.address
    // this.model.city = this.$store.getters.getUserInfo.city
    // this.model.country = this.$store.getters.getUserInfo.country
    // this.model.zipCode = this.$store.getters.getUserInfo.zipCode
    // this.model.about = this.$store.getters.getUserInfo.about
  },
  methods: {
    updateAccount() {
      return new Promise(() => {
        axios
          .post("https://eloboost-tn.herokuapp.com/api/user/updateProfile", {
            _id: this.$store.getters.getUserInfo._id,
            userName: this.model.username,
            // firstName: this.model.firstName,
            // lastName: this.model.lastName,
          })
          .then((response) => {
            console.log(response);
            this.$toast.success("updated successfully");
          });
      });
    },
    changePassword() {
      if (this.model.cpassword === this.model.npassword) {
        this.$toast.error("choose another password");
      } else if (this.model.confirmPassword === this.model.npassword) {
        return new Promise(() => {
          axios
            .post("https://eloboost-tn.herokuapp.com/api/user/changePassword", {
              userId: this.$store.getters.getUserInfo._id,
              cpassword: this.model.cpassword,
              password: this.model.npassword,
            })
            .then((response) => {
              console.log(response);
              this.$toast.success("updated successfully");
            });
        });
      } else {
        this.$toast.error("new password and confirm password does not combine");
      }
    },
  },
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
</style>
