<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive table-padding">
      <vue-good-table
        styleClass="vgt-table bordered"
        theme="polar-bear"
        :columns="columns"
        :rows="users"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          placeholder: 'Search',
        }"
        :pagination-options="{
          enabled: true,
          perPage: 5,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
          class="table-added-element"
        >
          <span v-if="props.column.field === 'action'">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  @click="banAccount(props.row._id)"
                  >deactivate compte</a
                >
                <div v-if="props.row.state === 1">
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 2)"
                    >Switch to Booster</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 3)"
                    >Switch to Coach</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 4)"
                    >Switch to Booster and Coach</a
                  >
                </div>
                <div v-if="props.row.state === 2">
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 1)"
                    >Switch to User</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 3)"
                    >Switch to Coach</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 4)"
                    >Switch to Booster and Coach</a
                  >
                </div>
                <div v-if="props.row.state === 3">
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="(modals = true), updateUser(props.row)"
                    >Update profile</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 1)"
                    >Switch to User</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 2)"
                    >Switch to Booster</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 4)"
                    >Switch to Booster and Coach</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    v-if="props.row.displayHome == 0 || !props.row.displayHome"
                    @click="updateAccount(props.row._id, 3, 1)"
                    >Display in home page</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    v-if="props.row.displayHome == 1"
                    @click="updateAccount(props.row._id, 3, 0)"
                    >Dont display in home page</a
                  >
                </div>
                <div v-if="props.row.state === 4">
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="(modals = true), updateUser(props.row)"
                    >Update profile</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 1)"
                    >Switch to User</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 2)"
                    >Switch to Coach</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateAccount(props.row._id, 3)"
                    >Switch to Booster</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    v-if="props.row.displayHome == 0 || !props.row.displayHome"
                    @click="updateAccount(props.row._id, 4, 1)"
                    >Display in home page</a
                  >
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    v-if="props.row.displayHome == 1"
                    @click="updateAccount(props.row._id, 4, 0)"
                    >Dont display in home page</a
                  >
                </div>
                <!--                <a class="dropdown-item" href="#">Plus de détails</a>-->
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
              </template>
            </base-dropdown>
          </span>
        </template>
      </vue-good-table>
    </div>

    <!-- <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <base-pagination total="30"></base-pagination>
    </div> -->
    <modal :show.sync="modals">
      <h2
        slot="header"
        class="modal-title"
        id="modal-title-default"
        style="color: #ed3029"
      >
        Change user price per hour
      </h2>
      <div class="row">
        <div class="col-lg-12">
          <base-input
            alternative=""
            label="Code du offre"
            placeholder="Code du offre"
            input-classes="form-control-alternative"
            v-model="pricePerHour"
          />
        </div>
        <div class="col text-right">
          <base-button
            style="background: #ed3029; border: none"
            size="md"
            @click="updateUserPrice()"
            >Update</base-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "projects-table-user",
  props: {
    type: {
      type: String,
    },
    title: String,
    users: Array,
  },
  data() {
    return {
      pricePerHour: 1,
      userSelected: {},
      modals: false,
      columns: [
        {
          label: "Name",
          field: "userName",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Date",
          field: "createdAt",
          formatFn: this.dateFormat,
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "100px",
        },
      ],
    };
  },
  mounted() {
    console.log(this.$props);
    // setTimeout((this.rows = this.$props.users), 500);
  },
  methods: {
    updateUser(user) {
      console.log(user);
      this.userSelected = user;
      if (user.pricePerHour) {
        this.pricePerHour = user.pricePerHour;
      } else {
        this.pricePerHour = 1;
      }
    },

    updateUserPrice() {
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken"),
        },
      };
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/updateProfile",
          {
            pricePerHour: this.pricePerHour,
            _id: this.userSelected._id,
          },
          config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken);
          this.$toast.success("updated successfully");
          this.$router.go();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Error during updating",
            type: "error",
            position: "bottom-right",
            // all of other options may go here
          });
        });
    },
    dateFormat(date) {
      return new Date(date).toLocaleString();
    },
    dateFormatForBirthday(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "-";
    },
    // checkName(firstName, lastName) {
    //   let res = "-";
    //   if (firstName) {
    //     res = firstName;
    //   }
    //   if (lastName) {
    //     res += " " + lastName;
    //   }
    //   return res;
    // },
    banAccount(userId) {
      console.log(userId);
      return new Promise(() => {
        axios
          .post("https://eloboost-tn.herokuapp.com/api/user/ban", {
            user: userId,
          })
          .then((response) => {
            console.log(response);
            this.$toast.success("banned successfully 😈");
          });
      });
    },
    updateAccount(id, arg, dHome) {
      if (dHome) {
        var obj = {
          _id: id,
          state: arg,
          displayHome: dHome,
        };
      } else {
        var obj = {
          _id: id,
          state: arg,
        };
      }
      let config = {
        headers: {
          authToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken"),
        },
      };
      axios
        .post(
          "https://eloboost-tn.herokuapp.com/api/user/updateProfile",
          obj,
          config
        )
        .then((response) => {
          localStorage.setItem("token", response.data.authToken);

          this.$toast.success("updated successfully");
          this.$router.go();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Error during updating",
            type: "error",
            position: "bottom-right",
            // all of other options may go here
          });
        });
    },
  },
};
</script>
<style></style>
